import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import CarbonCallout from '../components/CarbonCallout/CarbonCallout';
import Decorator from '../components/Decorator/Decorator';
import Frame from '../components/Frame/Frame';
import Hero from '../components/Hero/Hero';
import IconMail from '../components/Icons/IconMail';
import IconPhone from '../components/Icons/IconPhone';
import IconSignpost from '../components/Icons/IconSignpost';
import QuoteBlock from '../components/QuoteBlock/QuoteBlock';
import Seo from '../components/Seo/Seo';

import mapImg from '../images/map.jpg';

const ContactUsPage = (): JSX.Element => (
    <Fragment>
        <Seo title="Contact us" />
        <Hero height="small">
            <Hero.Body containerWidth="medium" padding="medium">
                <Frame layout="two-col" gap={6}>
                    <div className="space-y-1">
                        <h1 className="text-title gold">We'd love to hear from you</h1>
                        <p className="text-paragraph">
                            We're always happy to answer any questions you may have about your project.
                        </p>
                        <p className="text-paragraph">
                            Please feel free to get in touch by phone, email or fill out our <Link to="/project-planner" title="Complete our project planner" className="text-underline decoration-bronze-500">project planner</Link> and we'll get back to you as soon as possible.
                        </p>
                        { /* <LinkButton href="/project-planner" label="Start your project" icon={<IconArrowRight stroke="slate-900" size="24px" />} /> */ }
                    </div>
                    <div className="space-y-5">
                        <div className="space-y-3">
                            <div className="flex gap-1 items-center justify-center md:justify-start">
                                <IconPhone size="24px" />
                                <a href="tel:07791636845" className="text-subheading">07791 636 845</a>
                            </div>
                            <div className="flex gap-1 items-center justify-center md:justify-start">
                                <IconMail size="24px" />
                                <a href="mailto:office@constructsbuilding.co.uk" className="text-subheading">office@constructsbuilding.co.uk</a>
                            </div>
                            <div className="flex gap-1 items-start justify-center md:justify-start">
                                <IconSignpost size="24px" />
                                <address className="text-subheading">
                                    30 Stanwick Drive<br />
                                    Wyman's Brook<br />
                                    Cheltenham<br />
                                    GL51 9LG
                                </address>
                            </div>
                        </div>
                        <div className="space-y-3">
                            <span className="block text-heading">Opening hours</span>
                            <span className="block text-subheading">Monday - Friday 08:00 - 16:30</span>
                        </div>
                    </div>
                </Frame>
            </Hero.Body>
        </Hero>
        <div className="space-y-7">
            <section className="contain containLarge">
                <img src={mapImg} alt="A map displaying the location of Constructs Group's registered company address" className="w-full" />
            </section>
            <div className="text-center">
                <Decorator />
            </div>
            <section className="contain containMedium">
                <Frame layout="two-col">
                    <div className="space-y-1">
                        <span className="text-subheading">Constructs Group is a Cheltenham based general building business focused on providing high-quality, fine finish construction work. </span>
                        <p className="text-metadata">We believe our work ethic and attention to detail set us apart from our local competitors. We have a fantastic local reputation, as well as a steady amount of repeat custom. We pride ourselves as a team on customer focus and high quality craftsmanship as a standard.</p>
                    </div>
                    <QuoteBlock
                        author=""
                        alignment="text-left"
                        quote="We built our business by caring about what we do, and about our customers. We have a strong attention to detail, a fully appreciated work ethic, a habit of communication, and thrive greatly on technicality."
                    />
                </Frame>
            </section>
            <CarbonCallout />
        </div>
    </Fragment>
);

export default ContactUsPage;
